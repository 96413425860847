// reducers/authReducer.js
import { 
  BOOK_LESSON_SUCCESS, 
  GET_FRIENDS, 
  GET_LESSONS, 
  GET_USER_INFO, 
  IS_LOGGED, 
  LOGIN_FAILURE, 
  LOADER_ACTIVE, 
  LOGIN_SUCCESS, 
  SERVER_FAILURE, 
  CANCEL_BOOK_LESSON_SUCCESS, 
  LOADER_STOP, 
  REGISTER_SUCCESS, 
  REGISTER_FAILURE, 
  CLEAR_ERROS, 
  SET_FILTERED_LESSONS, 
  CHANGE_EMAIL, 
  PASSWORD_RESET_SUCCESS, 
  PASSWORD_RESET_FAIL, 
  PASSWORD_RESET_CONFIRM_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  CLEAR_ERRORS
} from "../actions/Types";

const initialState = {
  login_errors: '',
  isLoading: { id: null, status: false },
  isLogged: false,
  user: {},
  lessons: [],
  filtered_lessons: [],
  friends: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADER_ACTIVE:
      return {
        ...state,
        isLoading: { id: action.payload, status: true },
      };
      
    case LOADER_STOP:
      return {
        ...state,
        isLoading: { id: null, status: false },
      };

    case CLEAR_ERROS:
      return {
        ...state,
        login_errors: '',
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLogged: true,
        login_errors: '',
      };

    case LOGIN_FAILURE:
    case REGISTER_FAILURE:
    case SERVER_FAILURE:
      return {
        ...state,
        login_errors: action.payload,
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
      };

    case IS_LOGGED:
      return {
        ...state,
        isLogged: true,
      };

    case GET_LESSONS:
      return {
        ...state,
        lessons: action.payload,
      };

    case SET_FILTERED_LESSONS:
      return {
        ...state,
        filtered_lessons: action.payload,
      };

    case BOOK_LESSON_SUCCESS:
    case CANCEL_BOOK_LESSON_SUCCESS:
      return {
        ...state,
      };

    case GET_FRIENDS:
      return {
        ...state,
        friends: action.payload,
      };

    case GET_USER_INFO:
      return {
        ...state,
        user: action.payload,
      };

    case CHANGE_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload.email,
        },
      };

    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        login_errors: action.payload,
      };

    case PASSWORD_RESET_FAIL:
      return {
        ...state,
        login_errors: action.payload,
      };

      case PASSWORD_RESET_CONFIRM_FAIL:
      return {
        ...state,
        login_errors: action.payload,
      };

      case PASSWORD_RESET_CONFIRM_SUCCESS:
      return {
        ...state,
        login_errors: action.payload,
      };

      case CLEAR_ERRORS:
      return {
        ...state,
        login_errors: '',
      };
    default:
      return state;
  }
}
