import React, { useState } from 'react';
import './index.css';
import { paymentOptions } from './stripe_options';
import { createCheckoutSession } from './api';
import Loader from '../Loader';
const CreditsPurchase = ({ user_info }) => {
  const [loadingButton, setLoadingButton] = useState(null); // Estado para rastrear el botón que está cargando

  const handleCheckout = async (priceId, credits) => {
    setLoadingButton(priceId); // Inicia la carga para el botón específico

    try {
      const { sessionId } = await createCheckoutSession(priceId, credits, user_info.id);
      if (sessionId) {
        const {REACT_APP_STRIPE_KEY} = process.env
        const stripe = window.Stripe(REACT_APP_STRIPE_KEY);
        await stripe.redirectToCheckout({ sessionId });
      } else {
        console.error('Error al crear la sesión');
      }
    } catch (error) {
      console.error('Error al procesar el pago:', error);
    } finally {
      setLoadingButton(null); // Termina la carga
    }
  };

  return (
    <div className="class-purchase">
      <h1 className="title">Elige tu Plan de Clases</h1>
      <div className="payment-grid">
        {/* {paymentOptions.map((option, index) => (
          <div key={index} className="payment-option">
            <h2>{option.title}</h2>
            <p>{option.description}</p>
            <p><strong>€{option.amount}</strong></p>
            <button
              className="stripe-button"
              onClick={() => handleCheckout(option.id, option.credits)}
              disabled={loadingButton === option.id} // Desactiva solo el botón específico
            >
              {loadingButton === option.id ? <Loader/> : 'Stripe'}
            </button>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default CreditsPurchase;
