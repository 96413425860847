import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import './style.css';

const Header = ({  setActiveComponent ,handleShowBooked, handleShowMyLevels, handleClearFilters,active_component }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  const creditsHandleClick = () => {
    setActiveComponent('credits');
  };

  return (
    <AppBar position='fixed' className='nav' style={{ backgroundColor: '#A000FF' }}>
      <Toolbar className='nav-container'>
      
      {/*W8 4 TIMI*/}
      
      {/* <IconButton
          onClick={creditsHandleClick}
          id='filters-button'
          aria-controls={open ? 'filters-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MonetizationOnOutlinedIcon fontSize='large' style={{ color: 'white' }} />
        </IconButton> */}
        
        { active_component === 'home' &&
        <>
        <IconButton
          onClick={handleClick}
          id='filters-button'
          aria-controls={open ? 'filters-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <FilterAltOutlinedIcon fontSize='large' style={{ color: 'white' }} />
        </IconButton>

          <Menu
          id='filters-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'filters-button',
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              handleShowMyLevels();
            }}
          >
            Clases de mi nivel
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              handleClearFilters();
            }}
          >
            Sin filtros
          </MenuItem>
        </Menu> 
        </>
        }

        <IconButton onClick={handleLogout} style={{ color: 'white' }}>
          <LogoutOutlinedIcon fontSize='large' />
        </IconButton>

      </Toolbar>

    </AppBar>
  );
};

export default Header;
