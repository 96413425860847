// SweetAlert.js
import Swal from 'sweetalert2';

const SweetAlert = {
    success: (title, text,onConfirm) => {
        Swal.fire({
            icon: 'success',
            title: `<span style="font-family: 'Raleway', sans-serif; color: #a000ff;">${title}</span>`,
            html: `<span style="color: #a000ff;">${text}</span>`,
            background: '#ffffff',
            iconColor: '#a000ff',
            showCancelButton: false,
            confirmButtonColor: '#a000ff'
        }).then(() => {
            if (onConfirm) {
                onConfirm(); // Ejecuta la función de confirmación si está definida
            }
        });
    },
    error: (title, text) => {
        Swal.fire({
            icon: 'error',
            title: `<span style="font-family: 'Raleway', sans-serif; color: #a000ff;">${title}</span>`,
            html: `<span style="color: #a000ff;">${text}</span>`,
            background: '#ffffff',
            iconColor: '#a000ff',
            showCancelButton: false,
            confirmButtonColor: '#a000ff'
        });
    },
    warning: (title, text) => {
        Swal.fire({
            icon: 'warning',
            title: `<span style="font-family: 'Raleway', sans-serif; color: #a000ff;">${title}</span>`,
            html: `<span style="color: #a000ff;">${text}</span>`,
            background: '#ffffff',
            iconColor: '#a000ff',
            showCancelButton: false,
            confirmButtonColor: '#a000ff'
        });
    },
    info: (title, text) => {
        Swal.fire({
            icon: 'info',
            title: `<span style="font-family: 'Raleway', sans-serif; color: #a000ff;">${title}</span>`,
            html: `<span style="color: #a000ff;">${text}</span>`,
            background: '#ffffff',
            iconColor: '#a000ff',
            showCancelButton: false,
            confirmButtonColor: '#a000ff'
        });
    },
    confirm: (title, text, confirmButtonText, cancelButtonText, onConfirm) => {
        Swal.fire({
            title: `<span style="font-family: 'Raleway', sans-serif; color: #a000ff;">${title}</span>`,
            html: `<span style="color: #a000ff;">${text}</span>`,
            background: '#ffffff',
            icon: 'question',
            iconColor: '#a000ff',
            showCancelButton: true,
            confirmButtonColor: '#a000ff',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText
        }).then((result) => {
            if (result.isConfirmed) {
                onConfirm();
            }
        });
    }
};

// export const showSuccessAlert = () => {
//     Swal.fire({
//       title: '¡Transacción completada!',
//       text: 'Gracias por tu compra.',
//       icon: 'success',
//       confirmButtonText: 'Cerrar',
//     });
//   };
  
//   export const showCancelAlert = () => {
//     Swal.fire({
//       title: 'Transacción cancelada',
//       text: 'Has cancelado la compra.',
//       icon: 'warning',
//       confirmButtonText: 'Cerrar',
//     });
//   };

export default SweetAlert;
