import {Route,Routes} from 'react-router-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Landing from './containers/Landing'
// import Paypal from './components/Paypal/paypal_layout';
import Footer from './components/Footer';
import About from './components/About';
import React, {  useState } from 'react';
import {  useSelector } from 'react-redux';
import Landing2 from './v2/screens/Landing';
import Header from './components/Header'
import './App.css';

import General from './components/Info';

import CreditsPurchase from './components/Stripe';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import ResetPasswordScreen from './components/ResetPasswordForm';

const theme = createTheme({
  palette: {
    primary: {
      main: '#A000FF',
    },
    secondary: {
      main: '#FF9100',
    },
  },
});

function App() {
  let user_info = useSelector(state=>state.user)
  let showAbout = window.localStorage.getItem('token')
  const lessons_available = useSelector(state => state.lessons);
  const [showBooked, setShowBooked] = useState(false);
  const [lesson_day, setLessonDay] = useState(new Date());
  const [showUserLevel, setShowUserLevel] = useState(false); // Nuevo estado para mostrar solo clases del nivel del usuario
  const [activeComponent, setActiveComponent] = useState('home');
  const handleShowBooked = ()=>{
    setShowBooked(!showBooked)
    setShowUserLevel(false)
  }
  const handleShowMyLevels = ()=>{
    setShowUserLevel(!showUserLevel)
    setShowBooked(false)
  }
  const handleClearFilters = ()=>{
    setShowUserLevel(false)
    setShowBooked(false)
  }
  const handleShowDayLesson = (date)=>{
    setLessonDay(date)
  }
  const renderComponent = () => {
    switch (activeComponent) {
      case 'home':
        return <Landing lessons_available={lessons_available} showBooked={showBooked} showUserLevel={showUserLevel} handleShowDayLesson={handleShowDayLesson} lesson_day={lesson_day}/>;
      case 'profile':
        return <About user={user_info} all_lessons={lessons_available}/>;
      case 'general':
        return <General user={user_info}/>;
      case 'credits':
          return <CreditsPurchase user_info={user_info}/>;
          
      default:
        return <Landing />;
    }
  };

  const V1 = (
    <>
      {showAbout ? 
      <div>
        <Header  setActiveComponent={setActiveComponent} handleShowBooked={handleShowBooked} handleShowMyLevels={handleShowMyLevels} handleClearFilters={handleClearFilters} active_component={activeComponent}/>
        <Footer setActiveComponent={setActiveComponent}/> 
      </div>: null
      }
      <div className="content">
        {renderComponent()}
      </div>
    </>
  )

  const MuiCalendar = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar />
      </LocalizationProvider>
    );
  }

  const elements = document.querySelectorAll('[aria-rowindex]');
  const elementsArray = Array.from(elements);

  var rowIndex;

for (let element of elementsArray) {
    let button = element.querySelector('button.MuiPickersDay-today');
    if (button) {
        rowIndex = element.getAttribute('aria-rowindex');
        break;
      }
    }
    

  return (
      <MuiThemeProvider theme={theme}>
    <div className="App">
      <Routes>
      <Route path='/' element={<>{V1}</>}/>
      <Route path="/reset-password/:uid/:token" element={<ResetPasswordScreen />} />
      {/* <Route path='/mui-calendar' element={<MuiCalendar/>}/> */}
      {/* <Route path='/v2' element={<Landing2/>}/> */}
      </Routes>
    </div>
      </MuiThemeProvider>
  );
}

export default App;
