// screens/ResetPasswordScreen.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordConfirm } from '../../redux/actions/users';
import SweetAlert from '../sweetAlert';
import Loader from '../Loader';
import { clearErrors, loader_action, loader_stop_action } from '../../redux/actions/loader';
import './index.css'

const ResetPasswordScreen = () => {
  const { uid, token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Obtener los estados del reducer
  const isLoading = useSelector((state) => state.isLoading.status);
  const feedbackMessage = useSelector((state) => state.login_errors);

  const handleLoader = () => {
    dispatch(loader_action('change_password'))
    
  };


  useEffect(() => {
    // Mostrar SweetAlert y redirigir si se completa con éxito
    if (feedbackMessage) {
      if (feedbackMessage.includes("éxito")) {
        SweetAlert.success("Contraseña restablecida", "Se ha restablecido tu contraseña exitosamente.",navigate('/'));
      } else {
        SweetAlert.error("Error", feedbackMessage);
      }
    }
    return () => {
      dispatch(clearErrors());
    }
  }, [isLoading, navigate]);

  const handleSubmit = () => {
    handleLoader()
    dispatch(resetPasswordConfirm(uid, token, newPassword));
    setTimeout(() => {
        dispatch(loader_stop_action())
      }, 1000);
  };

  return (
    <div className="reset_password_screen">
      <h2>Establecer nueva contraseña</h2>
      <input
        type="text"
        placeholder="Nueva contraseña"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <button onClick={handleSubmit} disabled={isLoading}>
        {isLoading ? <Loader /> : 'Establecer contraseña'}
      </button>

      <button onClick={()=> navigate('/')} disabled={isLoading}>
        {'Inicio'}
      </button>
    </div>
  );
};

export default ResetPasswordScreen;
