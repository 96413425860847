import axios from 'axios';
import { BOOK_LESSON_SUCCESS, CANCEL_BOOK_LESSON_SUCCESS, CLEAR_FILTERED_LESSONS, GET_FRIENDS, GET_LESSONS, GET_USER_INFO, SERVER_FAILURE, SET_FILTERED_LESSONS} from './Types';
import utils from '../../utils/lessons'

const {REACT_APP_API_URL} = process.env

export const get_lessons = (alumn_id)=>{
    return async function(dispatch){
      try {
        const response = await axios.get(`${REACT_APP_API_URL}/api/class/?alumn_id=${alumn_id}`);
        return dispatch({
          type:GET_LESSONS,
          payload:response?.data
        })
      } catch (error) {
        console.log(error);
      }
    }
  }
  
  export const book_lesson = (lesson_id,alumn_id)=>{
    return async function(dispatch){
      try {
        const response = await axios.put(`${REACT_APP_API_URL}/api/class/${lesson_id}/add_alumn/ `,{'alumn_id':alumn_id});
        if (response.status ===200){
          return dispatch({
            type:BOOK_LESSON_SUCCESS,
          })

        }
      } catch (error) {
        return dispatch({
            type:SERVER_FAILURE,
            payload:error.response?.data.error
          })
      }
    }
  }

  export const cancel_book_lesson = (lesson_id,alumn_id)=>{
    return async function(dispatch){
      try {
        const response = await axios.put(`${REACT_APP_API_URL}/api/class/${lesson_id}/cancel_booking/ `,{'alumn_id':alumn_id});
        if (response.status ===200){
          return dispatch({
            type:CANCEL_BOOK_LESSON_SUCCESS,
          })

        }
      } catch (error) {
        return dispatch({
            type:SERVER_FAILURE,
            payload:error.response?.data.error
          })
      }
    }
  }



export const setFilteredLessons = (allLessons,user,filter) => {
  return async function(dispatch){
    let filtered_lessons;
    if (filter == 'Booked'){
      filtered_lessons= allLessons.filter(e => utils.is_booked(e?.alumns_display, user?.username))
    }
    if (filter == 'Level'){
        filtered_lessons= allLessons.filter(e => utils.verifyMyLessons(user?.levels,e?.minimum_level_display))
    }
    if (filter == 'None'){
      filtered_lessons=allLessons
    }
    
    return dispatch({
        type:SET_FILTERED_LESSONS,
        payload:filtered_lessons
      })
    
    }
  }


  // Estas se pueden mover de lugar

  export const get_personal_info = (alumn_id)=>{
    return async function(dispatch){
      try {
        const response = await axios.get(`${REACT_APP_API_URL}/api/alumns/${alumn_id}`);
        return dispatch({
          type:GET_USER_INFO,
          payload:response.data
        })
      } catch (error) {
        console.log(error);
      }
    }
  }
  
  export const get_friends = ()=>{
    return async function(dispatch){
      try {
        const response = await axios.get(`${REACT_APP_API_URL}/api/alumns/`);
        
        return dispatch({
          type:GET_FRIENDS,
          payload:response.response?.data
        })
      } catch (error) {
        console.log(error);
      }
    }
  }