// // RECORDAR USAR SIEMPRE LAS VARIABLES DE ENTORNO CON REACT_APP_*****

import axios from 'axios';
import { IS_LOGGED, LOGIN_FAILURE, LOGIN_SUCCESS, REGISTER_FAILURE, REGISTER_SUCCESS, SERVER_FAILURE} from './Types';
const {REACT_APP_API_URL} = process.env

export const login = (username, password) => {
  return async dispatch => {
    try {
      const response = await axios.post(`${REACT_APP_API_URL}/api/login/`, {
        username: username,
        password: password
      });

      // Guarda el token en el almacenamiento local
      localStorage.setItem('token', response.data?.token.token);
      localStorage.setItem('alumn_id', response.data?.token.alumn_id)
      // Dispatch una acción de éxito
      dispatch({
        type: LOGIN_SUCCESS
      });
    } catch (error) {
      // Dispatch una acción de error
      if (error.request.status === 0 || error.request.status === 500 || error.request.status === 400){
        dispatch({
          type: SERVER_FAILURE,
          payload: `Lo siento, estamos con problemas... Intenta luego :)`
        });
      }
      if (error.request?.status === 401){
        dispatch({
          type: LOGIN_FAILURE,
          payload: `Nombre y/o contraseña incorrectos.`
        });
        
      }
    }
  };
};


export const is_logged = ()=>{
  return async function(dispatch){
    return dispatch({
      type:IS_LOGGED
    })
  }
}


export const register = (username, email, password) => {
  return async dispatch => {
    try {
      const response = await axios.post(`${REACT_APP_API_URL}/api/register/`, {
        username: username,
        email: email,
        password: password
      });

      // Dispatch una acción de éxito 
      if (response.status === 200) {
        localStorage.setItem('register_ok', "true");
        dispatch({
          type: REGISTER_SUCCESS
        });
      }
    } catch (error) {

      if (error.response) {
        console.log(error);
        
        if (error.response.status === 0 || error.response.status === 500) {
          dispatch({
            type: SERVER_FAILURE,
            payload: `Sorry, we're having some server problems... try later :)`
          });
        } else if (error.response.status === 400 || error.response.status === 401) {
          // Combina los errores en una sola cadena
          const errors = error.response.data?.errors;
          let errorMessage = 'Hubo un error en el registro, intenta luego.';

          if (errors && typeof errors === 'object') {
            // Concatenar todos los errores en una sola cadena
            errorMessage = Object.values(errors)
              .flat()
              .join(' ');
          } else if (typeof errors === 'string') {
            errorMessage = errors; // En caso de que sea un solo mensaje de error en string
          }

          dispatch({
            type: REGISTER_FAILURE,
            payload: errorMessage
          });
        }
      } else {
        dispatch({
          type: SERVER_FAILURE,
          payload: `Sorry, we're having some network problems... try later :)`

        });
      }
    }
  };
};

