import React from 'react';
import './index.css'; // Importa los estilos CSS para el componente
import utils from '../../utils/date';
import Loader from '../Loader';
import { useDispatch, useSelector } from 'react-redux';
import { loader_action, loader_stop_action } from '../../redux/actions/loader';
import SweetAlert from '../sweetAlert';
const LessonComponent = ({ id, date,teacher, assistants, assistants_n, level, max,female, is_booked, user, onBook, onCancel }) => {
  const isLoading = useSelector(state => state.isLoading)
  const dispatch = useDispatch()

  const handleLoader = (buttonId) => {
    dispatch(loader_action(buttonId))
    setTimeout(() => {
      dispatch(loader_stop_action());
    }, 1000);
  };


  const handleOnBook = (e) => {
    if(assistants_n >= max ){
      SweetAlert.error('No puedes anotarte :( ','Esta clase está llena')
    }
    
    if(!user.female && female ){
      SweetAlert.error('No puedes anotarte','Esta clase es solo femenino.')
    }
    e.preventDefault();
    onBook(id, user.id);
    handleLoader(id);
  };

  const handleOnCancel = (e) => {
    e.preventDefault();
    onCancel(id, user.id);
    handleLoader(id);
  };

  const class_confirmed = (day) => {
    if (utils.isLessThan10HoursRemaining(day) && is_booked) return true
    return false
  }

  const handleLessThan10Hours = () => {
    SweetAlert.warning('Ya falta poco!', 'No te puedes cancelar de la clase.');
};


  return (
    <div className='lesson-component'>
      <div className='lesson_title'>
        {female ? <h3><b>FEMENINO</b></h3>:null}
        <p>{utils.formatDate.getFecha(date)}</p>
        <p>{utils.formatDate.getHora(date)}</p>
        <p>{level}</p>
        {teacher ? <p>Profesor/a: {teacher}</p> : <p>Profesor/a: Timi</p>}
      </div>

      <div className='lesson_info'>
        <div className='left'>
          <p>Asistentes ({assistants_n}/{max}): </p>
          <p>{assistants}</p>
        </div>
        
        <div className='right'>
          {
            class_confirmed(date) ? 
            <button onClick={handleLessThan10Hours} >
              Confirmado
            </button>
            :
            is_booked ? 
            <button disabled={isLoading.id === id && isLoading.status} onClick={handleOnCancel} id={id}>
              {isLoading.id === id && isLoading.status ? <Loader small={true}/> : 'Cancelar'}
            </button>
            :
            <button disabled={isLoading.id === id && isLoading.status} onClick={handleOnBook} id={id}>
              {isLoading.id === id && isLoading.status ? <Loader small={true}/> : 'Anotarse'}
            </button>
          }
        
        </div>


      </div>
    </div>
  );
};

export default LessonComponent;
