import React from 'react';
import './index.css';
import { useDispatch} from 'react-redux';
import LessonComponent from '../../components/LessonComponent';
import utils from '../../utils/lessons';
import { book_lesson, cancel_book_lesson } from '../../redux/actions/lessons';
import SweetAlert from '../../components/sweetAlert';
import Calendar from '../../components/Calendar';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
const Lessons = ({ user,lessons_available,showUserLevel,handleShowDayLesson,lesson_day }) => {
  const dispatch = useDispatch();

  const handleBooking = (lessonId, alumnId) => {
    if (user.credits === 0) {
      return SweetAlert.error('No tienes suficientes créditos', 'Debes regularizar tu membresía.');
    } else dispatch(book_lesson(lessonId, alumnId));
  };

  const handleCancel = (lessonId, alumnId) => {
    dispatch(cancel_book_lesson(lessonId, alumnId));
  };

  const calendarDate = new Date(lesson_day);
  
  // Convertir la fecha del calendario a una cadena ISO en formato YYYY-MM-DD
  const calendarDateISO = calendarDate.toISOString().slice(0, 10);
  
  // Filtrar las clases disponibles basadas en la fecha del calendario
  let filteredLessons = lessons_available?.filter((e) => {
    // Obtener la parte de la fecha de e.date_and_time en formato YYYY-MM-DD
    const lessonDateISO = e.date_and_time.slice(0, 10);
  
    // Comparar si la fecha de la clase es igual a la fecha del calendario  
    return lessonDateISO === calendarDateISO;
  });
  
  
  filteredLessons = showUserLevel
    ? filteredLessons.filter(e => utils.verifyMyLessons(user?.levels,e?.minimum_level_display)) // Filtrar por nivel del usuario
    : filteredLessons;

    filteredLessons = filteredLessons.sort((a, b) => {
      return new Date(a.date_and_time) - new Date(b.date_and_time);
    })

  return (
    <div className='lesson_container'>
      <Calendar handleShowDayLesson={handleShowDayLesson}/>

      {filteredLessons.length === 0 ? 
        <div className='no_lesson'>
          <ErrorOutlineIcon style={{ width: '150px', height: '150px',color: '#a000ff',border: '2px white solid',boxShadow:'4 px 4px' }}/>
          <h1>No hay clases planeadas</h1>
        </div>
       : 
        filteredLessons.map(e => (
          <LessonComponent
            key={e?.id}
            id={e?.id}
            date={e?.date_and_time}
            teacher={e?.teacher_display}
            assistants={e?.alumns_display}
            assistants_n={utils.assistant_counter(e?.alumns_display)}
            level={e?.minimum_level_display}
            max={e?.max_capacity}
            female={e?.female}
            is_booked={utils.is_booked(e?.alumns_display, user.username)}
            user={user}
            onBook={handleBooking}
            onCancel={handleCancel}
          />
        ))
      }

    </div>
  );
};

export default Lessons;
