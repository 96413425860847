function assistant_counter(assistants){
  if (assistants.length === 0) return 0
    return assistants.split(',').length
}

function is_booked(assistants, username) {
    // Divide la cadena de nombres en un array
    const usernames = assistants.split(', ');
    // Verifica si al menos un nombre de usuario está presente en el otro string
    for (const name of usernames) {
      if (username && username?.includes(name) && name !== '') {
        return true;
      }
    }
  
    // Si ningún nombre de usuario está presente, retorna false
    return false;
  }

function verifyMyLessons(array, str) {
    // Itera sobre cada elemento del array
    for (let i = 0; i < array.length; i++) {
      // Verifica si el elemento actual está incluido en el string
      if (str.includes(array[i])) {
        // Si encuentra coincidencia, devuelve true
        return true;
      }
    }
    // Si no se encuentra ninguna coincidencia, devuelve false
    return false;
  }
export default {
  assistant_counter,
  is_booked,
  verifyMyLessons
};
