import axios from "axios";
import { CHANGE_USERNAME, SERVER_FAILURE,CHANGE_EMAIL,PASSWORD_RESET_SUCCESS,PASSWORD_RESET_FAIL, PASSWORD_RESET_CONFIRM_SUCCESS, PASSWORD_RESET_CONFIRM_FAIL } from "./Types";

const { REACT_APP_API_URL } = process.env;

export const change_username = (alumn_id, new_username) => {
  return async function(dispatch) {
    try {
      const response = await axios.put(`${REACT_APP_API_URL}/api/alumns/${alumn_id}/put_username/`, { 'username': new_username });
      if (response.status === 200) {
        return dispatch({
          type: CHANGE_USERNAME,
          payload: new_username // Incluye el nuevo nombre de usuario en la carga útil
        });
      }
    } catch (error) {
      return dispatch({
        type: SERVER_FAILURE,
        payload: error.response?.data.error || 'Error desconocido' // Manejo de errores más robusto
      });
    }
  }
}


export const change_email = (alumn_id, new_email) => {
  return async function(dispatch) {
    try {
      const response = await axios.put(`${REACT_APP_API_URL}/api/alumns/${alumn_id}/put_email/`, { 'email': new_email });
      if (response.status === 200) {
        return dispatch({
          type: CHANGE_EMAIL,
          payload:  {email: new_email} // Incluye el nuevo nombre de usuario en la carga útil
        });
      }
    } catch (error) {
      return dispatch({
        type: SERVER_FAILURE,
        payload: error.response?.data.error || 'Error desconocido' // Manejo de errores más robusto
      });
    }
  }
}



export const resetPasswordRequest = (email) => async (dispatch) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}/api/password-reset/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (response.ok) {
      dispatch({
        type: PASSWORD_RESET_SUCCESS,
        payload: 'Se ha enviado un enlace de restablecimiento de contraseña a tu email.',
      });
    } else {
      const data = await response.json();
      console.log(data.email,'DATAAA');
      
      dispatch({
        type: PASSWORD_RESET_FAIL,
        payload: data.email || 'No se pudo enviar el correo.',  // Usa el mensaje de error del backend si existe
      });
    }
  } catch (error) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
      payload: 'Hubo un problema al enviar el correo. Inténtalo de nuevo.',
    });
  }
};



export const resetPasswordConfirm = (uid, token, newPassword) => async (dispatch) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}/api/password-reset-confirm/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uid, token, new_password: newPassword }),
    });

    if (response.ok) {
      dispatch({
        type: PASSWORD_RESET_CONFIRM_SUCCESS,
        payload: 'La contraseña se ha restablecido con éxito.',
      });
    } else {
      const data = await response.json();
      dispatch({
        type: PASSWORD_RESET_CONFIRM_FAIL,
        payload: 'No se pudo cambiar la contraseña.',
      });
    }
  } catch (error) {
    dispatch({
      type: PASSWORD_RESET_CONFIRM_FAIL,
      payload: 'Hubo un problema al restablecer la contraseña. Inténtalo de nuevo.',
    });
  }
};