import React, {  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import './index.css'
import { loader_action, loader_stop_action } from '../../redux/actions/loader';
const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch()
  const login_errors = useSelector(state => state.login_errors)
  let isLoading = useSelector(state => state.isLoading)
  if (isLoading){
    isLoading = isLoading.status
  }
  const handleLoader = () => {
    dispatch(loader_action('login'))
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      onLogin(username, password);
      handleLoader()
      document.body.style.fontSize = '16px';
      setTimeout(() => {
        dispatch(loader_stop_action())
      }, 1000);
  };

  return (
    <div>
      <h2>Iniciar sesión</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Nombre de usuario:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {
          login_errors ? 
          <p>{login_errors}</p>
          :
          <></>
        }
        <button type="submit" disabled={isLoading} id="loginButton">
        {isLoading ? <Loader/> : 'Login'}
      </button>
      </form>
    </div>
  );
};

export default Login;
