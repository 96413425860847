import React, { useState,useEffect } from 'react';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import EuroIcon from '@mui/icons-material/Euro';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { change_email } from '../../redux/actions/users';
import { loader_action, loader_stop_action } from '../../redux/actions/loader';
import Loader from '../Loader';

import './index.css'; // Importa los estilos CSS

const General = ({ user }) => {
  const [newEmail, setNewEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.isLoading?.status || false);  // Mejora en la obtención del estado

  const handleChangeEmail = (alumnId, new_email) => {
    dispatch(change_email(alumnId, new_email));
  };

  const handleLoader = (buttonId) => {
    dispatch(loader_action(buttonId));
    setTimeout(() => {
      dispatch(loader_stop_action());
    }, 2000);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmitEmail = (e) => {
    e.preventDefault();

    // Validar el correo electrónico antes de enviarlo
    if (!validateEmail(newEmail)) {
      setEmailError('Por favor ingrese un correo electrónico válido.');
      return;
    }

    setEmailError('');  // Limpiar el error si el correo es válido
    handleLoader('change_email');
    handleChangeEmail(user.id, newEmail);
    setNewEmail('');
  };
  useEffect(() => {
    // Solo es necesario re-renderizar cuando cambie el email
  }, [isLoading]);

  return (
    <div className="general" style={{ color: '#a000ff' }}>
      <div className="info-section">
        <AddReactionIcon fontSize="large" />
        <p>
          Los usuarios recién creados no tendrán nivel superior a Iniciante A, ya que están pendientes de aprobación. <br />
          Tampoco podrán reservar clases ya que no tienen asignados los créditos.
        </p>
      </div>
      <div className="info-section">
        <EuroIcon fontSize="large" />
        <p>
          Opciones de pago: <br />
          - Cash<br />
          - Transferencia Bancaria: Timea Kapusi, ES9715830001199030119774<br />
          - Bizum, Revolut, Wise, PayPal<br />
          Por favor envíe el comprobante para la asignación de créditos.
        </p>
      </div>
      <div className="info-section">
        <CloseIcon fontSize="large" />
        <p>
          Los packs se pueden finalizar dentro de un mes, después se caducarán.<br />
          Una vez confirmada la asistencia, no se puede cancelar el mismo día. La clase será cobrada independientemente de la asistencia.<br />
          Los grupos se organizan según el nivel y objetivo de las personas.
        </p>
      </div>

      {/* Formulario para cambiar el correo electrónico */}
      <div className='change-email'>
        <h3>{user.email ? user.email : 'ACTUALIZAR'}</h3>
        <h3>Cambiar correo electrónico:</h3>
        {isLoading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmitEmail}>
            <input
              type='email'
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder='Nuevo correo electrónico'
            />
            <button type='submit'>Cambiar</button>
          </form>
        )}
        {/* Mostrar el error de validación si es necesario */}
        {emailError && <p className='error-message'>{emailError}</p>}
      </div>
    </div>
  );
};

export default General;
