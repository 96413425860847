// containers/AuthenticationForm.js
import React, { useEffect, useState } from 'react';
import Login from '../../components/Login';
import Register from '../../components/Register';
import PasswordResetForm from '../../components/ResetPassword';
import './index.css';
import { useDispatch } from 'react-redux';
import { login, register } from '../../redux/actions/login';
import { clearErrors, loader_action } from '../../redux/actions/loader';

const AuthenticationForm = () => {
  const [formState, setFormState] = useState('login'); // 'login', 'register', 'forgotPassword'
  const dispatch = useDispatch();

  const handleLogin = (username, password) => {
    dispatch(loader_action('login'));
    dispatch(login(username, password));
  };

  const handleRegister = (username, email,password) => {
    dispatch(loader_action('login'));
    dispatch(register(username, email,password));
  };
  const handleResetPassword = () =>{
    dispatch(clearErrors())
    setTimeout(()=>setFormState('forgotPassword'),200)
    
  }


  useEffect(()=>{
    dispatch(clearErrors())
    
  },[formState])


  const renderForm = () => {
    switch (formState) {
      case 'login':
        return (
          <>
            <Login onLogin={handleLogin} />
            <p>
              ¿No tienes una cuenta?{' '}
              <button onClick={() => setFormState('register')} id="changeFormButton">
                Crear cuenta
              </button>
            </p>
            <p>
              <button onClick={() => handleResetPassword()} id="forgotPasswordButton">
                Olvidé mi contraseña
              </button>
            </p>
          </>
        );
      case 'register':
        return (
          <>
            <Register onRegister={handleRegister} />
            <p>
              ¿Ya tienes una cuenta?{' '}
              <button onClick={() => setFormState('login')} id="changeFormButton">
                Iniciar sesión
              </button>
            </p>
          </>
        );
      case 'forgotPassword':
        return <PasswordResetForm onBack={() => setFormState('login')} />;
      default:
        return null;
    }
  };

  return <div className='auth_form'>{renderForm()}</div>;
};

export default AuthenticationForm;
