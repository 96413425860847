import { CLEAR_ERRORS, LOADER_ACTIVE, LOADER_STOP } from "./Types"

export const loader_action = (buttonId) => {
    if (buttonId === 'login' || buttonId == 'change_username'){
      return async function(dispatch){
        return dispatch({
          type: LOADER_ACTIVE,
          payload: 'login'
        })  
      }    
    }  
    return async function(dispatch){
      return dispatch({
        type: LOADER_ACTIVE,
        payload: buttonId
      })  
    }  
  }  
  
  export const loader_stop_action = () => {
    return async function(dispatch){
      return dispatch({
        type: LOADER_STOP,
      })  
    }  
  }  
  

  export const clearErrors = () => {
    return {
      type: CLEAR_ERRORS,
    };
  };