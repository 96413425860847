import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader({small}) {
  return (
    <Stack sx={{ color: '#a000ff' }} spacing={2} direction="row">
      <CircularProgress color="secondary" size={small ? 20 : 24}/>
    </Stack>
  );
}
