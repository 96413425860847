import React, { useState } from 'react';
import './index.css';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import AddIcon from '@mui/icons-material/Add';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
function Footer({ setActiveComponent }) {
  const [activeButton, setActiveButton] = useState('home');

  const handleClick = (component) => {
    setActiveButton(component);
    setActiveComponent(component);
  };

  return (
    <div className="footer">
      <button
        onClick={() => handleClick('general')}
        className={activeButton === 'general' ? 'active' : ''}
      >
        <LiveHelpIcon fontSize="large" />
      </button>
      <button
        onClick={() => handleClick('home')}
        className={activeButton === 'home' ? 'active' : ''}
      >
        <ScheduleIcon fontSize="large" />
      </button>
      <button
        onClick={() => handleClick('profile')}
        className={activeButton === 'profile' ? 'active' : ''}
      >
        <PersonOutlineIcon fontSize="large" />
      </button>
    </div>
  );
}

export default Footer;
