import $ from 'jquery'; // Importa jQuery
import SweetAlert from '../components/sweetAlert';
export function checkTokenAndRemoveForm() {
  const token = localStorage.getItem('token');
  if (token) {
    // Si hay un token, oculta el contenedor del formulario de autenticación
    $('#authenticationFormContainer').fadeOut(1000, function() {
      $(this).empty(); // Vacía el contenedor después de la animación de desvanecimiento
    });
  }
}

$('#loginButton').on('click', function() {
  setTimeout(() => {
    checkTokenAndRemoveForm();
  }, 3000); // Verificar el token después de 5 segundos
});



export function verificarValorLocalStorageYHacerClicEnBoton() {
  // Verificar si el valor en el localStorage es true
  var isValueTrue = localStorage.getItem('register_ok');
  // Si el valor en el localStorage es true, hacer clic en un botón
  if (isValueTrue === "true") {
      $('#changeFormButton').click(); // Reemplaza 'tu_boton_id' con el ID de tu botón
      SweetAlert.success('Felicitaciones!','Usuario creado con éxito')
  }
  setTimeout(()=>{
    localStorage.removeItem('register_ok')
  },5000  )
}