import React, { useEffect, useState } from 'react';
import './index.css'; // Importa los estilos CSS para la landing
import utils from '../../utils/lessons';
import LessonComponent from '../LessonComponent';
import { useDispatch, useSelector } from 'react-redux';
import { cancel_book_lesson, get_lessons, get_personal_info } from '../../redux/actions/lessons';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { change_username } from '../../redux/actions/users';
import { loader_action, loader_stop_action } from '../../redux/actions/loader';
import Loader from '../Loader';

const About = ({ user, all_lessons }) => {
  const dispatch = useDispatch();
  const is_loading = useSelector(state => state.isLoading);
  const [booked_lessons, setBookedLessons] = useState([]);
  const [newUsername, setNewUsername] = useState('');
  let isLoading = useSelector(state => state.isLoading)
  if (isLoading){
    isLoading = isLoading.status
  }
  const handleCancel = (lessonId, alumnId) => {
    dispatch(cancel_book_lesson(lessonId, alumnId));
  };

  const handleChangeUsername = (alumnId, new_username) => {
    dispatch(change_username(alumnId, new_username));
  };
  const handleLoader = (buttonId) => {
    dispatch(loader_action(buttonId))
    setTimeout(() => {
      dispatch(loader_stop_action());
    }, 2000);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleLoader('change_username')
    handleChangeUsername(user.id, newUsername);
    setNewUsername('');
  };

  useEffect(() => {
    if (user) {
      const alumn_id = user.id;
      dispatch(get_lessons(alumn_id));
      dispatch(get_personal_info(alumn_id));
    }
  }, [dispatch,is_loading]);

  useEffect(() => {
    if (user && all_lessons) {
      const lessons = all_lessons.filter(e => utils.is_booked(e?.alumns_display, user.username));
      setBookedLessons(lessons);
    }
  }, [is_loading, all_lessons, user]);

  return (
    <div className='container'>
      <div className='me'>
        {isLoading ?
        <Loader/>
        :
          <h1>{user?.username}</h1>

        }
        <h3>{user?.levels?.join(', ')}</h3>
        <h3>CLASES DISPONIBLES: {user?.credits}</h3>
      </div>
      <div className='change-username'>
          <h3>Cambiar nombre:</h3>
          {
            isLoading ? 
            <Loader/>
            :
          <form onSubmit={handleSubmit}>
            <input
              type='text'
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              placeholder='Nuevo nombre de usuario'
            />
            <button type='submit'>Cambiar</button>
          </form>
          }
        </div>
      {booked_lessons.length > 0 ? 
      <div>
      <h1>Reservadas: </h1>

      {booked_lessons.map(e =>{ 
        return <LessonComponent
        key={e?.id}
        id={e?.id}
        date={e?.date_and_time}
        assistants={e?.alumns_display}
        assistants_n={utils.assistant_counter(e?.alumns_display)}
        level={e?.minimum_level_display}
        max={e?.max_capacity}
        is_booked={utils.is_booked(e?.alumns_display, user.username)}
        user={user}
        onCancel={handleCancel}
        />
        })}
      </div>
      :
        <>
        <div className='no_booked'>
          <SentimentVeryDissatisfiedIcon style={{ width: '150px', height: '150px',color: '#a000ff',border: '2px white solid',boxShadow:'4 px 4px' }}/>
          <h1>No tienes clases reservadas</h1>
        </div>      </>
    }
    </div>
  );
};

export default About;
