import React, { useEffect, useState } from "react";
import DatePicker from "react-horizontal-datepicker";
import "./index.css"; // Archivo de estilos CSS personalizado

const Calendar = ({handleShowDayLesson}) => {
  
  const [endDate, setEndDate] = useState(12);

  const selectedDay = (val) => {
    const offsetDate = new Date(val.getTime() - (val.getTimezoneOffset() * 60000));
    handleShowDayLesson(offsetDate);
  };
  const calculateEndDate = () => {
    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const daysLeftInMonth = lastDayOfMonth.getDate() - today.getDate();
    if (daysLeftInMonth <= 7) {
      setEndDate(50);
    } else {
      setEndDate(12);
    }
  };

  useEffect(() => {
    calculateEndDate();
  }, []);

  return (
      <div className="calendar-container">
        <DatePicker
          getSelectedDay={selectedDay}
          labelFormat={"MMMM"}
          color={"#A000FF"}
          endDate={44}
        />
      </div>
  );
};

export default Calendar;
