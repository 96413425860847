import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import './index.css';
import { loader_stop_action } from '../../redux/actions/loader';
import { verificarValorLocalStorageYHacerClicEnBoton } from '../../js/auth';

const Register = ({ onRegister }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');  // Nuevo estado para el email
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const dispatch = useDispatch();
  const register_errors = useSelector(state => state.login_errors);
  let isLoading = useSelector(state => state.isLoading);

  if (isLoading) {
    isLoading = isLoading.status;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Las contraseñas no coinciden");
      return;
    }
    
    onRegister(username, email, password);
    
    setTimeout(() => {
      dispatch(loader_stop_action());
      verificarValorLocalStorageYHacerClicEnBoton();
    }, 2000);
  };

  return (
    <div>
      <h2>Crear cuenta</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Nombre de usuario:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label> 
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirmar contraseña:</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        {register_errors ? 
          <p>{register_errors}</p> : null
        }
        <button type="submit" disabled={isLoading} id="registerButton">
          {isLoading ? <Loader /> : 'Crear cuenta'}
        </button>
      </form>
    </div>
  );
};

export default Register;
