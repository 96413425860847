// components/PasswordResetForm.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordRequest } from '../../redux/actions/users';
import { clearErrors, loader_action, loader_stop_action } from '../../redux/actions/loader';
import SweetAlert from '../sweetAlert';
import Loader from '../Loader';

const PasswordResetForm = ({ onBack }) => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const feedbackMessage = useSelector((state) => state.login_errors); // Usa login_errors para el feedback
  const isLoading = useSelector((state) => state.isLoading.status);

  useEffect(() => {
    // Escucha cambios en feedbackMessage para mostrar SweetAlert
    if (feedbackMessage) {
      // Detener el loader
      dispatch(loader_stop_action());

      // Mostrar mensaje de éxito o error según el contenido de feedbackMessage
      if (feedbackMessage.includes("enviado")) {
        SweetAlert.success("Solicitud de restablecimiento enviada", "Por favor, revisa tu email para restablecer tu contraseña.",onBack);
      } else {
        SweetAlert.error("Error", feedbackMessage);
      }

      // Limpiar el mensaje de feedback después de mostrar la alerta
      dispatch(clearErrors());
    }
  }, [feedbackMessage, dispatch]);

  const handleSubmit = () => {
    dispatch(loader_action('change_password')); // Activar el loader
    dispatch(resetPasswordRequest(email));
  };

  return (
    <div className="password_reset_form">
      <h3>Restablecer contraseña</h3>
      <input
        type="email"
        placeholder="Introduce tu email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleSubmit} disabled={isLoading}>
        {isLoading ? <Loader /> : 'Enviar'}
      </button>
      <p>
        <button onClick={onBack}>Volver a Iniciar sesión</button>
      </p>
    </div>
  );
};

export default PasswordResetForm;
