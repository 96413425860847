import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import AuthenticationForm from '../AuthForm';
import AnimatedTitle from '../../components/AnimatedTitle';
import './index.css'; 
import { checkTokenAndRemoveForm } from '../../js/auth'; 
import { useDispatch, useSelector } from 'react-redux';
import { get_lessons, get_personal_info } from '../../redux/actions/lessons';
import { is_logged } from '../../redux/actions/login';
import Lessons from '../Lessons';
import { useLocation, useNavigate } from 'react-router-dom'; // Importa useNavigate para manipular la URL
import SweetAlert from '../../components/sweetAlert';
const Landing = ({handleShowDayLesson, lesson_day, lessons_available, showBooked, showUserLevel}) => {

  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const alumn_id = localStorage.getItem('alumn_id');
  let showAbout = useSelector(state => state.isLogged);
  let user_info = useSelector(state => state.user);
  const is_loading = useSelector(state => state.isLoading);

  const location = useLocation(); // Obtén la ubicación actual
  const navigate = useNavigate(); // Hook para manipular la navegación
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get('status'); // Obtén el valor de 'status' en la URL
  
  useEffect(() => {
    checkTokenAndRemoveForm();
    if (token) {
      dispatch(get_lessons(alumn_id));
      dispatch(get_personal_info(alumn_id));
      setTimeout(() => {
        dispatch(is_logged());
      }, 2000);
    }
    // Limpia el parámetro 'status' después de 2 segundos
    if (status) {
      setTimeout(() => {
        searchParams.delete('status'); // Elimina el parámetro 'status'
        navigate({
          search: searchParams.toString(), // Actualiza la URL sin recargar la página
        });
      }, 500);
    }

    // Mostrar SweetAlert basado en el parámetro 'status'
    if (status === 'success') {
      SweetAlert.success('Felicitaciones!','Se han cargado tus créditos con éxito'); // Muestra la alerta de éxito
    } else if (status === 'cancel') {
      SweetAlert.error('Error!','Se ha cancelado la transacción.');
    }


  }, [dispatch, token, alumn_id, showAbout, is_loading]);

  return (
    <div className="landing-container">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 50 }} // Configura la posición inicial y la opacidad
          animate={{ opacity: 1, y: 0 }} // Configura la animación de entrada
          transition={{ duration: 1 }} // Configura la duración de la animación
        >
          <AnimatedTitle />
        </motion.div>
      </AnimatePresence>
      {!token ? (
        <div id="authenticationFormContainer">
          <motion.div
            initial={{ opacity: 0, y: 50 }} // Configura la posición inicial y la opacidad
            animate={{ opacity: 1, y: 0 }} // Configura la animación de entrada
            exit={{ opacity: 0, y: -50 }} // Configura la animación de salida
            transition={{ duration: 0.5 }} // Configura la duración de la animación
          >
            <AuthenticationForm />
          </motion.div>
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 50 }} // Configura la opacidad inicial en 0
          animate={{ opacity: 1, y: 0 }} // Configura la animación de entrada para cambiar la opacidad a 1
          transition={{ duration: 1 }} // Configura la duración de la animación
        >
          <Lessons 
            user={user_info} 
            lessons_available={lessons_available} 
            showBooked={showBooked} 
            showUserLevel={showUserLevel} 
            handleShowDayLesson={handleShowDayLesson} 
            lesson_day={lesson_day}
          />
        </motion.div>
      )}
    </div>
  );
};

export default Landing;
