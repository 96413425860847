export const LOADER_ACTIVE='LOADER ACTIVE'
export const LOADER_STOP='LOADER STOP'
export const CLEAR_ERROS='CLEAR ERRORS'
export const IS_LOGGED='IS LOGGED'
export const LOGIN_FAILURE='LOGIN FAILURE'
export const LOGIN_SUCCESS='LOGIN SUCCESS'
export const REGISTER_SUCCESS='REGISTER SUCCESS'
export const REGISTER_FAILURE='REGISTER FAILURE'
export const SERVER_FAILURE= 'SERVER FAILURE'
export const GET_LESSONS='GET LESSONS'
export const GET_FRIENDS='GET FRIENDS'
export const GET_USER_INFO='GET USER INFO'
export const BOOK_LESSON_SUCCESS='BOOK LESSON'
export const CANCEL_BOOK_LESSON_SUCCESS='CANCEL BOOK LESSON'
export const SET_FILTERED_LESSONS='SET FILTERED LESSONS'
export const CHANGE_USERNAME='CHANGE USERNAME'
export const CHANGE_EMAIL='CHANGE EMAIL'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';